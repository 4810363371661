var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',[_c('v-btn',{staticClass:"float-right",on:{"click":_vm.resetUsedPowerUps}},[_vm._v(" Reset Used PowerUps ")]),_c('v-btn',{staticClass:"float-right",attrs:{"to":"/fkadmin"}},[_vm._v(" Admin Dashboard ")]),_c('h2',[_vm._v("Picks Admin")]),_c('v-row',[_c('v-col',{staticClass:"col-4 col-xs-12 col-md-4",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"col-4 col-xs-12 col-md-4",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.events,"item-text":"id","label":"Events","persistent-hint":"","return-object":"","single-line":""},on:{"change":_vm.filterEvent},model:{value:(_vm.selectedEvent),callback:function ($$v) {_vm.selectedEvent=$$v},expression:"selectedEvent"}})],1),_c('v-col',{staticClass:"col-4 col-xs-12 col-md-4",attrs:{"cols":"4"}},[(!_vm.showPast)?_c('v-btn',{on:{"click":_vm.viewPast}},[_vm._v(" View Past ")]):_vm._e(),(_vm.showPast)?_c('v-btn',{on:{"click":_vm.viewUpcoming}},[_vm._v(" View Upcoming ")]):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.picks,"item-key":"uid","search":_vm.search,"must-sort":""},on:{"current-items":_vm.getGoodUsers},scopedSlots:_vm._u([{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)),_c('br'),_c('small',[_vm._v("("+_vm._s(item.owner)+")")])]}},{key:"item.eventID",fn:function(ref){
var item = ref.item;
return [(item.eventID)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.eventID,"dddd MMM Do"))+" ")]):_vm._e()]}},{key:"item.modified",fn:function(ref){
var item = ref.item;
return [(item.modified)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.modified.seconds,"dddd MMM Do"))+" ")]):_vm._e()]}},{key:"item.data.length",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(item.data.length)+" ")]),_c('br')]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-none"},[_vm._v(_vm._s(item))]),_c('v-expansion-panels',_vm._l((item.data),function(item,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',[_c('v-chip',[_vm._v("Points:"+_vm._s(item.points))]),_c('v-chip',[_vm._v("PUs:"+_vm._s(item.powerUpPoints))]),_c('v-chip',[_vm._v(_vm._s(item.picked)+":"+_vm._s(item.fighter_name))]),_c('v-chip',[_vm._v("("+_vm._s(item.ufc_fight_id)+")")]),(item.missedWeight)?_c('v-chip',{attrs:{"color":"error"}},[_vm._v("missedWeight: "+_vm._s(item.missedWeight)+" ")]):_vm._e()],1),_c('v-expansion-panel-content',[(item.powerUps)?_c('div',[(item.powerUps.length)?_c('v-data-table',{attrs:{"dense":"","hide-default-footer":"","headers":[{
                  text: 'Name',
                  value: 'name',
                  },
                  { text: 'Country', value: 'country' },
                  { text: 'MoV', value: 'mov' },
                  { text: 'Tier', value: 'tier' },
                  { text: 'Points', value: 'powerUpPointsBreakdown' } ],"items":item.powerUps,"item-key":"uid","disable-pagination":true,"disable-items-per-page":true},scopedSlots:_vm._u([{key:"item.powerUpPointsBreakdown",fn:function(ref){
                  var item = ref.item;
return [(item.powerUpPointsBreakdown)?_c('ul',_vm._l((item.powerUpPointsBreakdown),function(point,value){return _c('li',{key:value,staticClass:"text-xs"},[_vm._v(" "+_vm._s(value)+"="+_vm._s(point)+" ")])}),0):_vm._e()]}}],null,true)}):_vm._e()],1):_vm._e()])],1)}),1)]}},{key:"item.totalPoints",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.totalPoints + item.totalPowerUpPoints)),_c('br')])]}},{key:"item.data.points",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.totalPoints)),_c('br')])]}},{key:"item.totalPowerUpPoints",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.totalPowerUpPoints)),_c('br')])]}}])})],1)],1),_c('v-card',{staticClass:"mt-6"},[_c('v-card-title',[_vm._v(" Reminders ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.reminderHeaders,"items":_vm.reminderUsers,"item-key":"uid","search":_vm.reminderSearch},scopedSlots:_vm._u([{key:"top",fn:function(){return [(!_vm.reminderUsers.length)?_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mx-auto",attrs:{"outlined":""},on:{"click":_vm.getAllUsers}},[_vm._v(" Show users ")])],1):_vm._e(),(_vm.reminderUsers.length)?_c('div',{staticClass:"mx-auto text-center"},[_c('vue-json-to-csv',{attrs:{"json-data":_vm.reminderUsers,"csv-title":'reminderUsers'}},[_c('v-btn',{staticClass:"ma-4",attrs:{"color":"success"}},[_vm._v(" Export "),_c('i',{staticClass:"mdi mdi-export-variant",attrs:{"aria-hidden":"true"}})])],1)],1):_vm._e(),_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.reminderSearch),callback:function ($$v) {_vm.reminderSearch=$$v},expression:"reminderSearch"}})]},proxy:true},{key:"item.userUID",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)),_c('br'),_c('small',[_vm._v("("+_vm._s(item.userUID)+")")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }