<template>
  <div>
    <v-card>
      <v-card-text>
        <v-btn
          class="float-right"
          @click="resetUsedPowerUps"
        >
          Reset Used PowerUps
        </v-btn>
        <v-btn
          class="float-right"
          to="/fkadmin"
        >
          Admin Dashboard
        </v-btn>
        <h2>Picks Admin</h2>
        <v-row>
          <v-col
            cols="4"
            class="col-4 col-xs-12 col-md-4"
          >
            <v-text-field
              v-model="search"
              label="Search"
            ></v-text-field>
          </v-col>
          <v-col
            cols="4"
            class="col-4 col-xs-12 col-md-4"
          >
            <v-select
              v-model="selectedEvent"
              :items="events"
              item-text="id"
              label="Events"
              persistent-hint
              return-object
              single-line
              @change="filterEvent"
            ></v-select>
          </v-col>
          <v-col
            cols="4"
            class="col-4 col-xs-12 col-md-4"
          >
            <v-btn
              v-if="!showPast"
              @click="viewPast"
            >
              View Past
            </v-btn>
            <v-btn
              v-if="showPast"
              @click="viewUpcoming"
            >
              View Upcoming
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="picks"
          item-key="uid"
          class="elevation-1"
          :search="search"
          must-sort
          @current-items="getGoodUsers"
        >
          <template v-slot:item.owner="{ item }">
            {{ item.name }}<br>
            <small>({{ item.owner }})</small>
          </template>
          <template v-slot:item.eventID="{ item }">
            <span v-if="item.eventID">
              {{ item.eventID|moment("dddd MMM Do") }}
            </span>
          </template>
          <template v-slot:item.modified="{ item }">
            <span v-if="item.modified">
              {{ item.modified.seconds|moment("dddd MMM Do") }}
            </span>
          </template>
          <template v-slot:item.data.length="{ item }">
            <v-chip>
              {{ item.data.length }}
            </v-chip>
            <br>
          </template>
          <template v-slot:item.data="{ item }">
            <span class="d-none">{{ item }}</span>
            <v-expansion-panels>
              <v-expansion-panel v-for="(item,i) in item.data" :key="i">
                <v-expansion-panel-header>
                  <v-chip>Points:{{ item.points }}</v-chip>
                  <v-chip>PUs:{{ item.powerUpPoints }}</v-chip>
                  <v-chip>{{ item.picked }}:{{ item.fighter_name }}</v-chip>
                  <v-chip>({{ item.ufc_fight_id }})</v-chip>
                  <v-chip v-if="item.missedWeight" color="error">missedWeight: {{
                      item.missedWeight
                    }}
                  </v-chip>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-if="item.powerUps">
                    <v-data-table
                      dense
                      hide-default-footer
                      v-if="item.powerUps.length"
                      :headers="[{
                    text: 'Name',
                    value: 'name',
                    },
                    { text: 'Country', value: 'country' },
                    { text: 'MoV', value: 'mov' },
                    { text: 'Tier', value: 'tier' },
                    { text: 'Points', value: 'powerUpPointsBreakdown' },
                    ]"
                      :items="item.powerUps"
                      item-key="uid"
                      :disable-pagination="true"
                      :disable-items-per-page="true"
                    >
                      <template v-slot:item.powerUpPointsBreakdown="{ item }">
                        <ul v-if="item.powerUpPointsBreakdown">
                          <li v-for="(point,value) in item.powerUpPointsBreakdown" :key="value"
                              class="text-xs">
                            {{ value }}={{ point }}
                          </li>
                        </ul>
                      </template>
                    </v-data-table>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
          <template v-slot:item.totalPoints="{ item }">
            <span>
           {{ item.totalPoints + item.totalPowerUpPoints }}<br>
            </span>
          </template>
          <template v-slot:item.data.points="{ item }">
            <span>
           {{ item.totalPoints }}<br>
            </span>
          </template>
          <template v-slot:item.totalPowerUpPoints="{ item }">
            <span>

              {{ item.totalPowerUpPoints }}<br>
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>
        Reminders
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="reminderHeaders"
          :items="reminderUsers"
          item-key="uid"
          class="elevation-1"
          :search="reminderSearch"
        >
          <template v-slot:top>
            <div
              v-if="!reminderUsers.length"
              class="text-center"
            >
              <v-btn
                outlined
                class="mx-auto"
                @click="getAllUsers"
              >
                Show users
              </v-btn>
            </div>
            <div
              v-if="reminderUsers.length"
              class="mx-auto text-center"
            >
              <vue-json-to-csv

                :json-data="reminderUsers"
                :csv-title="'reminderUsers'"
              >
                <v-btn
                  color="success"
                  class="ma-4"
                >
                  Export <i
                  class="mdi mdi-export-variant"
                  aria-hidden="true"
                ></i>
                </v-btn>
              </vue-json-to-csv>
            </div>
            <v-text-field
              v-model="reminderSearch"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:item.userUID="{ item }">
            {{ item.name }}<br>
            <small>({{ item.userUID }})</small>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'
import firebase from 'firebase/app'
import dbFunctions from '@/db'

export default {
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      search: '',
      reminderSearch: '',
      reminderUsers: [],
      picks: [],
      goodUsers: [],
      events: [],
      selectedEvent: '',
      showPast: false,
    }
  },
  computed: {
    headers() {
      return [
        {text: 'Member', value: 'owner'},
        {text: 'Event', value: 'eventID'},
        {text: 'Modified', value: 'modified'},
        {text: 'Picks Made', value: 'data.length'},
        {text: 'Picks Data', value: 'data'},
        {text: 'TotalPoints', value: 'totalPoints'},
        {text: 'Points', value: 'data.points'},
        {text: 'PowerUps', value: 'totalPowerUpPoints'},
      ]
    },
    reminderHeaders() {
      return [
        {text: 'Member', value: 'userUID'},
        {text: 'Email', value: 'email'},
      ]
    },
  },
  mounted() {
    this.getMembersPicks()
    this.getEvents()
  },
  methods: {
    resetUsedPowerUps() {
      console.log(this.picks)
      for (let i = 0; i < this.picks.length; i += 1) {
        dbFunctions.resetPowerUps(this.picks[i].owner)
      }
    },
    viewPast() {
      this.showPast = true
      this.getEvents()
    },
    viewUpcoming() {
      this.showPast = false
      this.getEvents()
    },
    getEvents() {
      dbFunctions.allUFCEvents(this.showPast)
        .then(events => {
          this.events = events
        })
    },
    getMembersPicks() {
      dbFunctions.getAllPicks().then(data => {
        this.picks = data
        for (let i = 0; i < this.picks.length; i += 1) {
          this.userItem(this.picks[i].owner, i)
        }
      })
    },
    userItem(userUid, i) {
      let memberData = {}
      const member = firebase.functions().httpsCallable('getUser')
      member({uid: userUid})
        .then(user => {
          memberData = user
          this.picks[i].name = memberData.data.displayName
          if (this.reminderUsers.length) {
            this.reminderUsers[i].name = memberData.data.displayName
            this.reminderUsers[i].email = memberData.data.email
          }

          return memberData.data.displayName
        })

      return memberData
    },
    async getAllUsers() {
      await dbFunctions.getAllUserProfile().then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          if (!this.goodUsers.includes(data.userUID)) {
            this.reminderUsers.push(data)
          }
        })

        for (let i = 0; i < this.reminderUsers.length; i += 1) {
          this.userItem(this.reminderUsers[i].userUID, i)
        }
      })
        .catch(error => {
          console.log(error)
        })
    },
    filterEvent(e) {
      this.search = this.$moment(e.id, 'YYYY-MM-DD').format('MMMM-DD-YYYY')
    },
    getGoodUsers(e) {
      this.goodUsers = []
      for (let i = 0; i < e.length; i += 1) {
        this.goodUsers.push(e[i].owner)
      }
    },
  },
}
</script>

<style lang="scss">
</style>
